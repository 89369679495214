.SearchInputComponent {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  justify-content: flex-end;
}
.SearchInput {
  margin-bottom: 0px !important;
  margin-top: 1em;
}
